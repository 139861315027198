import { type PortableTextComponentProps } from '@portabletext/react'
import cx from 'classnames'
import dynamic from 'next/dynamic'

import { type SanityContentInfoBox } from '@data/sanity/queries/types/content'

const SimplePortableText = dynamic(
  () => import('@components/simple-portable-text')
)

type InfoBoxValue = Pick<SanityContentInfoBox, 'title' | 'content'>

const InfoBox = ({ value }: PortableTextComponentProps<InfoBoxValue>) => {
  const { title, content } = value

  return (
    <div className="info-box bg-green-dark rounded-md p-6 pb-3 sm:p-8 sm:pb-5">
      {title && (
        <h4 className="!mb-4 text-yellow text-[1.375rem] leading-[1.625rem]">
          {title}
        </h4>
      )}

      {content && (
        <SimplePortableText
          content={content}
          className={cx(
            'whitespace-pre-wrap !text-white !my-0 text-[1.125rem] leading-[1.625rem]'
          )}
        />
      )}
    </div>
  )
}

export default InfoBox
