import { type PortableTextComponentProps } from '@portabletext/react'
import dynamic from 'next/dynamic'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { screens } from '@lib/theme'

const Photo = dynamic(() => import('@components/photo'))

const Figure = ({ value }: PortableTextComponentProps<SanityImageFragment>) => {
  return (
    <Photo
      image={value}
      sizes={`(max-width: ${screens.md}) 100vw, ${screens.md}`}
    />
  )
}

export default Figure
