import { type PortableTextMarkComponentProps } from '@portabletext/react'

const colorClasses: Record<string, string> = {
  white: 'text-white',
  'gray-light': 'text-gray-light',
  gray: 'text-gray',
  'green-dark': 'text-green-dark',
  yellow: 'text-yellow',
}

interface ColoredTextValue {
  _type: string
  color?: string
}

const ColoredText = ({
  value,
  children,
}: PortableTextMarkComponentProps<ColoredTextValue>) => {
  const className = value?.color ? colorClasses[value.color] : null

  return <span className={className ?? ''}>{children}</span>
}

export default ColoredText
