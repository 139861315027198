import { type PortableTextMarkComponentProps } from '@portabletext/react'

import { type SanityContentBlockLink } from '@data/sanity/queries/types/content'

import CustomLink from '@components/link'

type LinkValue = SanityContentBlockLink

const Link = ({
  value,
  children,
}: PortableTextMarkComponentProps<LinkValue>) => {
  if (!value) {
    return null
  }

  return <CustomLink link={value}>{children}</CustomLink>
}

export default Link
