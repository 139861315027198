import { PortableText } from '@portabletext/react'
import {
  type ArbitraryTypedObject,
  type PortableTextBlock,
} from '@portabletext/types'

import ColoredText from './portable-text/colored-text'
import Figure from './portable-text/figure'
import HorizontalRule from './portable-text/horizontal-rule'
import InfoBox from './portable-text/info-box'
import Link from './portable-text/link'
import LottieAnimation from './portable-text/lottie-animation'
import Quote from './portable-text/quote'
import VideoButton from './portable-text/video-button'
import VideoEmbed from './portable-text/video-embed'

type ComplexPortableTextBlock = PortableTextBlock | ArbitraryTypedObject

interface ComplexPortableTextProps {
  content: ComplexPortableTextBlock[]
  className?: string
}

const ComplexPortableText = ({
  content,
  className,
}: ComplexPortableTextProps) => {
  return (
    <div className={className}>
      <PortableText
        value={content}
        components={{
          block: {
            none: ({ children }) => <>{children}</>,
            small: ({ children }) => <p className="text-base">{children}</p>,
            normal: ({ children }) => <p>{children}</p>,
            preamble: ({ children }) => (
              <p className="text-preamble !mb-4">{children}</p>
            ),
            h1: ({ children }) => <h1>{children}</h1>,
            h2: ({ children }) => <h2>{children}</h2>,
            h3: ({ children }) => <h3>{children}</h3>,
            h4: ({ children }) => <h4>{children}</h4>,
            h5: ({ children }) => <h5>{children}</h5>,
            h1mock: ({ children }) => <p className="is-h1">{children}</p>,
            h2mock: ({ children }) => <p className="is-h2">{children}</p>,
            h3mock: ({ children }) => <p className="is-h3">{children}</p>,
            h4mock: ({ children }) => <p className="is-h4">{children}</p>,
            h5mock: ({ children }) => <p className="is-h5">{children}</p>,
          },
          types: {
            figure: Figure,
            horizontalRule: HorizontalRule,
            infoBox: InfoBox,
            lottieAnimation: LottieAnimation,
            quote: Quote,
            videoButton: VideoButton,
            videoEmbed: VideoEmbed,
          },
          marks: {
            link: Link,
            textColor: ColoredText,
          },
        }}
      />
    </div>
  )
}

export default ComplexPortableText
