import { type PortableTextComponentProps } from '@portabletext/react'
import cx from 'classnames'
import dynamic from 'next/dynamic'
import { useContext } from 'react'

import {
  type HasFontCase,
  type SanityButtonStyle,
} from '@data/sanity/queries/types/link'
import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { type SanityVideo } from '@data/sanity/queries/types/video'
import { SiteContext } from '@lib/site'

import Button, {
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'

const Photo = dynamic(() => import('@components/photo'))

type VideoButtonValue = HasFontCase & {
  id?: string
  text?: string
  style: SanityButtonStyle
  video: SanityVideo
  thumbnail?: SanityImageFragment
}

const VideoButton = ({
  value,
}: PortableTextComponentProps<VideoButtonValue>) => {
  const { id, text, video, thumbnail, fontCase, style } = value

  const { toggleVideoModal } = useContext(SiteContext)

  if (thumbnail?.asset) {
    return (
      <button
        id={id}
        onClick={() => toggleVideoModal(true, video)}
        className="relative !opacity-100 group"
      >
        <Photo
          image={thumbnail}
          className="group-hover:brightness-90 transition duration-300"
        />

        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex items-center justify-center bg-yellow text-green-dark w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] uppercase text-sm lg:text-base font-medium rounded-full p-4 group-hover:scale-105 transition-all duration-300">
            {text}
          </div>
        </div>
      </button>
    )
  }

  const variant = getButtonVariant(style.variant)
  const size = getButtonSize(style.size)
  const color = getButtonColor(style.color)
  const iconAlignment = getButtonIconAlignment(style.iconAlignment)

  return (
    <Button
      id={id}
      onClick={() => toggleVideoModal(true, video)}
      variant={variant}
      size={size}
      color={color}
      icon={style.icon}
      iconAlignment={iconAlignment}
      className={cx(
        'btn',
        {
          'w-full': style.isFullWidth,
        },
        fontCase ?? ''
      )}
    >
      {text}
    </Button>
  )
}

export default VideoButton
