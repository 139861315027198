import { type PortableTextComponentProps } from '@portabletext/react'
import cx from 'classnames'

import { type SanityContentHorizontalRule } from '@data/sanity/queries/types/content'

const HorizontalRule = ({
  value,
}: PortableTextComponentProps<SanityContentHorizontalRule>) => {
  return <hr className={cx(`bg-${value.color ?? 'current'}`)} />
}

export default HorizontalRule
